import React, { Component } from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import Layout from './layout'
import styled from 'styled-components'

import Seo from '../components/seo'

const PostContent = styled.div`
  max-width: 75ch;
  margin: 0 auto;
  background: white;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 3px 10px rgba(25, 17, 34, 0.1);

  > * {
    grid-column: 2;
  }

  .full-bleed {
    width: 100%;
    grid-column: 1 / 4;
  }
`

const BackBtn = styled.span`
  align-self: flex-end;
  display: inline-block;
  padding: 0.3rem 0.8rem;
  margin: 1rem 0;
  font-family: var(--sans-serif);
  font-size: 0.8rem;
  color: white;
  background: #1ba39c;
  border-radius: 3rem;
  transition: background 0.3s ease;

  &:hover {
    background: #097771;
    color: white;
  }
`

export default class postLayout extends Component {
  render() {
    const { markdownRemark } = this.props.data
    const { location } = this.props
    return (
      <>
        <Seo title={markdownRemark.frontmatter.title} />
        <Layout location={location}>
          <PostContent
            className="full-bleed"
            dangerouslySetInnerHTML={{
              __html: markdownRemark.html,
            }}
          />
          <Link to="/">
            <BackBtn>&larr; Back Home</BackBtn>
          </Link>
        </Layout>
      </>
    )
  }
}

export const query = graphql`
  query PostQuery($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date
        slug
      }
    }
  }
`
